<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">
        Клиент {{ client.name }} (ID: {{ client.id }})
      </h1>
      <button @click="onUpdate1C" class="button button--light button--no-indent" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.564 7.732v.733H4.64v9.507h1.428V7H2.564v.732zm9.026-.676c-.681.08-1.59.428-2.206.847a5.39 5.39 0 00-1.902 2.234 5.456 5.456 0 00-.523 2.362c.002 1.83.92 3.564 2.412 4.558.66.44 1.27.672 2.184.833.526.092 1.507.109 6.652.11h4.792v-1.434l-5.345-.01c-5.74-.012-5.467-.006-6.152-.147a3.957 3.957 0 01-1.457-.634c-.377-.269-.89-.835-1.124-1.244a4.042 4.042 0 01-.54-2.041 4.066 4.066 0 01.667-2.256c.194-.304.74-.862 1.043-1.069.468-.317.94-.521 1.477-.637.324-.07 1.162-.07 1.476 0 .814.18 1.458.537 2.03 1.123a4.009 4.009 0 011.149 2.51l.017.256h1.42l-.012-.076a5.62 5.62 0 01-.026-.325 5.254 5.254 0 00-.527-1.95 5.234 5.234 0 00-1.076-1.514 5.39 5.39 0 00-2.752-1.456c-.266-.055-1.336-.08-1.677-.04zM1 9.902v.691h1.564v7.38h1.375V9.211H1v.69zm11.04-.521a2.984 2.984 0 00-2.124 1.158 3.625 3.625 0 00-.574 1.173 3.51 3.51 0 00-.054 1.283c.112.658.395 1.22.85 1.69.242.25.469.418.793.588.302.16.522.23.954.303.34.058.391.058 5.73.067l5.385.01-.007-.7-.007-.698-5.433-.014-5.432-.014-.212-.061c-.355-.104-.546-.216-.789-.463a1.683 1.683 0 01-.487-.947c-.144-.848.406-1.709 1.243-1.943.297-.084.697-.069.986.037.606.221 1.023.757 1.118 1.436l.018.131H15.35l-.018-.242c-.06-.85-.565-1.745-1.262-2.238a2.974 2.974 0 00-2.031-.556z" fill="#2B93E7"/></svg>
        <span>Обновить данные из 1С</span>
      </button>
    </div>
    <div class="content__tabs">
      <div class="tabs-list">
        <ul class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'client-detail' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Основные данные
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'users-list' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Пользователи
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'prices' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Цены
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'managers' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Менеджеры
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'client-orders' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Заказы
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ClientLayout',
  data() {
    return {
      client: {}
    }
  },
  mounted() {
    this.$store.dispatch('client/GET_DATA', { id: this.$route.params.id })
      .then(response => { this.client = response.data })
  },
  methods: {
    onUpdate1C() {
      this.$store.dispatch('dict/GET_COUNTERPARTIES_1C', this.client.main_counterparty.code_1c)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные успешно обновлены.'
          })
          location.reload()
        })
    },
  }
}
</script>
